import React from "react"
import styled from "styled-components"

// ======================
// 	     COMPONENT
// ======================

const Error = ({ error }) => {
  return (
    <Container>
      <p
        dangerouslySetInnerHTML={{
          __html: error,
        }}
      />
    </Container>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  color: red;
  p {
    color: red;
  }
`

export default Error
