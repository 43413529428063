import React, { useState, useEffect } from "react"
import styled from "styled-components"

import BlurLoader from "../reusable-components/loaders/blur-loader"
import LinkWrapper from "../reusable-components/link-wrapper"

import PasswordSubmit from "./reset-password/password-submit"
import EmailSubmit from "./reset-password/email-submit"
import Error from "./error"
import ShowOnButton from "./show-on-button"

import { getQueryVariable } from "../../lib/utils"
import { requestPassword, submitPassword } from "../../api/auth"

// ================
// 	  COMPONENT
// ================

const Reset = ({ location }) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [hasError, setHasError] = useState(false)
  const [hasMessage, setHasMessage] = useState(false)
  const [apiError, setApiError] = useState(false)
  const [tk, setTk] = useState(false)

  // Get token from URL if exists
  useEffect(() => {
    getQueryVariable("tok", setTk)
  })

  const submitHandler = ({ email, password }) => {
    setLoading(true)
    if (email) {
      requestPassword(email)
        .then((res) => {
          setLoading(false)
          if (res && res.message) {
            setMessage(res.message)
          }
          setHasError(false)
          setHasMessage(true)
        })
        .catch((error) => {
          setMessage(
            "Password reset has failed. Please check your email address."
          )
          setHasError(true)
          setLoading(false)
        })
    } else if (tk) {
      submitPassword(password, tk)
        .then((res) => {
          setLoading(false)
          if (res && res.message) {
            setMessage(res.message)
          }
          setHasMessage(true)
          setHasError(false)
        })
        .catch((error) => {
          setMessage(
            "Password reset has failed. The link you followed is invalid."
          )
          setHasError(true)
          setLoading(false)
        })
    }
  }

  return (
    <ResetPasswordPanelContainer>
      <BlurLoader loading={loading} message="Requesting" shade="light">
        <ResetPasswordFormSectionStyling>
          <h2>Reset your Password</h2>
          {message && <Extra color={hasError ? "red" : null}>{message}</Extra>}
          {!tk && !hasMessage && (
            <EmailSubmit
              ExtraStyled={Extra}
              message={message}
              submitHandler={submitHandler}
            />
          )}
          {tk && !hasMessage && (
            <PasswordSubmit
              ExtraStyled={Extra}
              message={message}
              submitHandler={submitHandler}
            />
          )}
          <Extra>
            Back to <LinkWrapper to="/login/">Login</LinkWrapper>
          </Extra>
          {apiError && <Error error={apiError.content} />}
          <ShowOnButton />
        </ResetPasswordFormSectionStyling>
      </BlurLoader>
    </ResetPasswordPanelContainer>
  )
}

// ===============
//     STYLES
// ===============
const ResetPasswordPanelContainer = styled.div`
  max-width: 350px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  .show-on {
    margin-top: 10px;
  }
`
const ResetPasswordFormSectionStyling = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  .half-width-input input {
    display: block;
  }
  label {
    width: 100%;
    display: inline-block;
    margin-top: 10px;
  }
  .half-width-input {
    max-width: 400px;
    width: 100%;
    display: inline-block;
  }
  & form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    .half-width-input {
      width: 48%;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .half-width-input {
      width: 100%;
    }
    & form > div {
      width: 100%;
    }
    & form > div button {
      width: 100%;
    }
  }
`
const Extra = styled.div`
  color: ${({ theme, color = "black1" }) => theme.colors[color]};
  text-align: center;
  font-weight: 400;
  a {
    color: ${({ theme }) => theme.colors.black1};
    text-decoration: underline;
  }
`

export default Reset
