import React from "react"

import Layout from "../components/theme/layout"
import SEO from "../components/theme/header/seo"
import Reset from "../components/account/reset-password-panel"

const ResetPage = ({ location }) => {
  const title = "Reset Password"

  return (
    <div>
      <Layout location={location}>
        <SEO title={title} location={location} yoast={{}} />
        <Reset />
      </Layout>
    </div>
  )
}

export default ResetPage
