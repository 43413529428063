import React from "react"
import styled from "styled-components"
import { Formik, Form } from "formik"

import AccountsFormInputField from "../accounts-form-input-field"

import SubmitButton from "../../reusable-components/buttons/submit-button"

// ================
// 	  COMPONENT
// ================

const EmailSubmit = ({ ExtraStyled, submitHandler, message }) => {
  return (
    <>
      {!message && (
        <ExtraStyled>
          Reset your password by submitting your email address below. We will
          send you an email with a link to choose a new password.
        </ExtraStyled>
      )}
      <Formik
        initialValues={{ email: "" }}
        validate={validate}
        onSubmit={submitHandler}
      >
        {({ isSubmitting, errors, touched }) => (
          <>
            <Form>
              <AccountsFormInputField
                label="Email"
                type="email"
                name="email"
                autoComplete="email"
                placeholder="jane@doe.com"
                errors={errors}
                touched={touched}
              />
              <StyledButton text="RESET" />
            </Form>
          </>
        )}
      </Formik>
    </>
  )
}

// ===============
//     STYLES
// ===============
const StyledButton = styled(SubmitButton)`
  margin: 20px 0;
  width: 100%;
`

// ===============
// 	   HELPERS
// ===============

const validate = ({ email }) => {
  const errors = {}
  const addIf = (pred, prop, message) => pred && (errors[prop] = message)

  addIf(!email, "email", "Email is required")
  addIf(!/\S+@\S+\.\S+/.test(email), "email", "Email is invalid")

  return errors
}

export default EmailSubmit
