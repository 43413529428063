import React from "react"
import styled from "styled-components"

import LinkWrapper from "../reusable-components/link-wrapper"
import SvgIcon from "../reusable-components/svg-icon"
import ChevronRight from "../../images/icons/chevron-right"

// ======================
// 	     COMPONENT
// ======================

const ShowOnButton = ({ desktop }) => {
  return (
    <ShowOn desktop={desktop} className="show-on">
      <Button to="/">
        <SvgIcon
          SvgComponent={ChevronRight}
          hue="white"
          shade="000"
          size="lg"
        />
        <div>HOMEPAGE</div>
      </Button>
    </ShowOn>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================

const ShowOn = styled.div`
  display: ${({ desktop }) => (desktop ? "block" : "none")};
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    display: ${({ desktop }) => (desktop ? "none" : "block")};
  }
`

const Button = styled(LinkWrapper)`
  border-radius: 3px;
  border: 1px solid white;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateX(-3px);
  }
  span.icon {
    border-right: 1px solid white;
  }
  div {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 24px;
    padding: 0 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    div {
      font-size: 20px;
    }
  }
`

export default ShowOnButton
